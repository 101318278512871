import React from 'react'
import { FaUserAlt, FaSms } from 'react-icons/fa'


function Info({ credit, subscribers }) {
    return (
        <section className="info my-4">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-2 gap-5">

                    <div className="rounded-lg shadow-lg flex justify-center items-center flex-col bg-teal-400 overflow-hidden text-white">
                        <div className='my-4 w-full px-2'>
                            <FaUserAlt className='text-3xl md:text-5xl' />
                            <h2 className='text-2xl md:text-4xl text-right'>{subscribers}</h2>
                            <h3 className='text-xl md:text-2xl text-right'>Subscribers</h3>
                        </div>
                        <div className="p-2 bg-teal-500 w-full">
                            <span className='text-sm md:text-xl'>Subscribers</span>
                        </div>
                    </div>

                    <div className="rounded-lg shadow-lg flex justify-center items-center flex-col bg-red-400 overflow-hidden text-white">
                        <div className='my-4 w-full px-2'>
                            <FaSms className='text-3xl md:text-5xl' />
                            <h2 className='text-2xl md:text-4xl text-right'>{credit}</h2>
                            <h3 className='text-xl md:text-2xl text-right'>SMS Credits</h3>
                        </div>
                        <div className="p-2 bg-primary w-full">
                            <a href="https://gstemplate28.grocerystorewebsites.com/" className='bg-primary px-5 py-1 rounded outline-none text-lg hover:bg-secondary shadow'>Purchase Credit</a>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Info