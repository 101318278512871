import axios from 'axios'

const apiUrl = "https://mobile-pigeon-web-app.herokuapp.com/api"
const storeUrl = `${apiUrl}/stores`

export const getAllStore = () => axios.get(storeUrl)
export const getSearchStore = (searchTerms) => axios.post(`${storeUrl}/search`, searchTerms)
export const addNewStore = (newStore) => axios.post(storeUrl, newStore)
export const updateStore = (id, updatedStore) => axios.patch(`${storeUrl}/${id}`, updatedStore)
export const deleteStore = (id) => axios.delete(`${storeUrl}/${id}`)
export const getAStore = (id) => axios.get(`${storeUrl}/${id}`)

export const adminLogin = (username, password) => axios.get(`${apiUrl}/admin/login?username=${username}&password=${password}`)