import React, { useEffect } from 'react'
import { FaEye, FaEdit } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { openEditStoreModal, openViewStoreModal } from '../../../redux/actions/adminModals'
import { pageCount, startStore, endStore } from '../../../redux/actions/storePagination'

const localStores = [
    {
        id: 1,
        image_url: "https://gstemplate10.grocerystorewebsites.com/wp-content/uploads/sites/107/2022/08/Your_Logo_Here_wide_transparent.png",
        storeName: "Piggly Wiggly",
        textNumber: "+ (111) 111 - 1112",
        apiKey: "INS1059443581",
        groupName: "Hello Group",
        username: "",
        password: "",
        email: ""
    },
    {
        id: 2,
        image_url: "https://gstemplate10.grocerystorewebsites.com/wp-content/uploads/sites/107/2022/08/Your_Logo_Here_wide_transparent.png",
        storeName: "Piggly Wiggly",
        textNumber: "+ (111) 111 - 1112",
        apiKey: "INS1059443581",
        groupName: "Hello Group",
        username: "",
        password: "",
        email: ""
    },
    {
        id: 3,
        image_url: "https://gstemplate10.grocerystorewebsites.com/wp-content/uploads/sites/107/2022/08/Your_Logo_Here_wide_transparent.png",
        storeName: "Piggly Wiggly",
        textNumber: "+ (111) 111 - 1112",
        apiKey: "INS1059443581",
        groupName: "Hello Group",
        username: "",
        password: "",
        email: ""
    },
    {
        id: 4,
        image_url: "https://gstemplate10.grocerystorewebsites.com/wp-content/uploads/sites/107/2022/08/Your_Logo_Here_wide_transparent.png",
        storeName: "Piggly Wiggly",
        textNumber: "+ (111) 111 - 1112",
        apiKey: "INS1059443581",
        groupName: "Hello Group",
        username: "",
        password: "",
        email: ""
    },
    {
        id: 5,
        image_url: "https://gstemplate10.grocerystorewebsites.com/wp-content/uploads/sites/107/2022/08/Your_Logo_Here_wide_transparent.png",
        storeName: "Piggly Wiggly",
        textNumber: "+ (111) 111 - 1112",
        apiKey: "INS1059443581",
        groupName: "Hello Group",
        username: "",
        password: "",
        email: ""
    },
    {
        id: 6,
        image_url: "https://gstemplate10.grocerystorewebsites.com/wp-content/uploads/sites/107/2022/08/Your_Logo_Here_wide_transparent.png",
        storeName: "Piggly Wiggly",
        textNumber: "+ (111) 111 - 1112",
        apiKey: "INS1059443581",
        groupName: "Hello Group",
        username: "",
        password: "",
        email: ""
    },
]


function Stores() {
    const stores = useSelector((state) => state.stores)
    const storePagination = useSelector((state) => state.storePagination)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            pageCount({ pageCount: Math.ceil(stores.length / storePagination.storePerPage) }),
            startStore({ startStore: storePagination.currentPage * storePagination.storePerPage }),
            endStore({ endStore: (storePagination.currentPage * storePagination.storePerPage) + storePagination.storePerPage })
        )
    }, [stores, dispatch])

    const handleViewStore = (store) => {
        dispatch(openViewStoreModal(store))
    }

    const handleEditStore = (store) => {
        dispatch(openEditStoreModal(store))
    }

    return (
        <section className="stores my-5">
            <div className="container mx-auto px-3 md:px-0">
                <h2 className='text-sm text-slate-400 mb-4'>total store: {stores?.length}</h2>
                <div className="wrapper grid grid-cols-1 md:grid-cols-2 gap-5">
                    {
                        stores && stores.length > 0 ? stores?.slice(storePagination.startStore, storePagination.endStore).map((store) => (
                            <Store storeDetail={store} key={store.id} handleViewStore={handleViewStore} handleEditStore={handleEditStore}></Store>
                        ))
                            : (
                                <></>
                            )
                    }
                </div>
            </div>
        </section>
    )
}

export default Stores

const Store = ({ storeDetail, handleViewStore, handleEditStore }) => (
    <div className='bg-secondary shadow rounded-lg flex justify-between overflow-hidden'>
        <div className="flex-1 p-4 text-white">
            <img src={storeDetail.image_url ? storeDetail.image_url : storeDetail.storeImageUrl} alt="" style={{ maxWidth: "100px", width: "100%", maxHeight: "100px", height: "auto", objectFit: "cover" }} />
            <h2 className='my-4 text-2xl font-medium'>{storeDetail.storeName}</h2>
            <ul>
                <li>{storeDetail.textNumber}</li>
                <li>{storeDetail.apiKey}</li>
                <li>{storeDetail.groupName}</li>
            </ul>
        </div>
        <div style={{ transform: "rotate(270deg) translateY(55%)" }}>
            <label htmlFor="my-modal" className='text-white bg-primary w-full py-3 px-10 flex items-center cursor-pointer'
                onClick={() => handleViewStore(storeDetail)}>
                <FaEye className='mr-3' />
                <span>View Store Info</span>
            </label>
            <label htmlFor="my-modal" className="text-white bg-[#000000] w-full py-3 px-10 flex items-center cursor-pointer"
                onClick={() => handleEditStore(storeDetail)}
            >
                <FaEdit className='mr-3' />
                <span>Edit Store Info</span>
            </label>
        </div>
    </div >
)