import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading'
import { adminLogin } from '../../redux/actions/adminData'
import { toast } from 'react-toastify'

function AdminLogin() {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const admin = useSelector((state) => state.adminData)
    let from = location.state?.from?.pathname || "/admin"
    const [formData, setFormData] = useState({ username: "", password: "" })

    const handleLogin = async (e) => {
        e.preventDefault()
        if (!formData.username || !formData.password) {
            return toast.warn(" All field are required")
        }
        dispatch(adminLogin(formData?.username, formData?.password))
    }

    if (!admin) {
        return <Loading></Loading>
    }

    if (admin?.message) {
        toast.error(admin.message)
    }

    // if (admin & admin.role == "admin") {
    if (admin?.role == "admin") {
        navigate(from, { replace: true })
    }


    return (
        <section className="login-container bg-secondary">
            <div className="container mx-auto px-4">
                <div className="h-screen flex justify-center items-center">
                    <div className="wrapper w-full">
                        <form onSubmit={handleLogin} className="shadow rounded-lg p-4 max-w-md w-full mx-auto"
                            style={{ background: "rgba(255, 255, 255, 0.6)" }}
                        >
                            <h2 className='text-2xl'>Please Login Now</h2>
                            <div className="my-2">
                                <label htmlFor="" className='text-sm text-gray'>Username:</label>
                                <input type="text" className='rounded shadow-sm py-1 pl-2 border border-slate-800 w-full outline-none text-sm' onChange={(e) => setFormData({ ...formData, username: e.target.value })} />
                            </div>
                            <div className="my-2">
                                <label htmlFor="" className='text-sm text-gray'>Password:</label>
                                <input type="text" className='rounded shadow-sm py-1 pl-2 border border-slate-800 w-full outline-none text-sm' onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
                            </div>
                            <div className="my-4">
                                <button className='text-center bg-slate-900 text-white py-1 px-5 rounded w-full text-lg font-medium' type='submit'>Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AdminLogin