export const openAddStoreModal = () => (dispatch) => {
    try {
        dispatch({ type: "ADD_ADMIN_MODAL" })
    } catch (error) {
        console.log(error.message)
    }
}

export const openEditStoreModal = (store) => (dispatch) => {
    try {
        dispatch({ type: "EDIT_ADMIN_MODAL", payload: store })
    } catch (error) {
        console.log(error.message)
    }
}

export const openViewStoreModal = (store) => (dispatch) => {
    try {
        dispatch({ type: "VIEW_ADMIN_MODAL", payload: store })
    } catch (error) {
        console.log(error.message)
    }
}

export const closeStoreModal = () => (dispatch) => {
    try {
        dispatch({ type: "CLOSE_ADMIN_MODAL" })
    } catch (error) {
        console.log(error.message)
    }
}