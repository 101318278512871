import { combineReducers } from "redux"

import stores from "./stores"
import adminModals from './adminModals'
import storePagination from "./storePagination"
import adminData from './adminData'

export default combineReducers(
    {
        stores,
        adminModals,
        storePagination,
        adminData
    }
)