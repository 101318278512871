import React from 'react'
import { FaSadTear } from 'react-icons/fa'


function NotFound() {
    return (
        <>
            <section className=''>
                <div className="h-screen flex justify-center items-center flex-col">
                    <FaSadTear className='mb-5 text-warning text-8xl' />
                    <h2 className='text-3xl text-error'>You are in wrong URL</h2>
                </div>
            </section>
        </>
    )
}

export default NotFound