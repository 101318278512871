import React from 'react'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { customPage, endStore, nextPage, previousPage, startStore } from '../../../redux/actions/storePagination'

function Pagination() {

    return (
        <section className="pagination mb-5 mt-12">
            <div className="container mx-auto px-3 md:px-0">
                <div className="wrapper flex justify-between items-center shadow-xl px-3 py-2 bg-[#F5F5F5]">
                    <PreviousButton />
                    <Pages />
                    <NextButton />
                </div>
            </div>
        </section>
    )
}

export default Pagination

const PreviousButton = () => {
    const storePagination = useSelector((state) => state.storePagination)
    const dispatch = useDispatch();

    return <>
        <button className={`flex justify-between items-center bg-secondary text-white rounded-2xl py-1 px-3 md:px-4 text-sm md:text-lg ${storePagination.currentPage > 1 ? "" : "pointer-events-none opacity-60"}`}
            onClick={() => {
                dispatch(previousPage())
                dispatch(startStore({ startStore: ((storePagination.currentPage - 1) * storePagination.storePerPage) - storePagination.storePerPage }))
                dispatch(endStore({ endStore: (storePagination.currentPage - 1) * storePagination.storePerPage }))
            }}>
            <FaChevronCircleLeft className='mr-2' />
            <span>Previous</span>
        </button>
    </>
}

const NextButton = () => {
    const storePagination = useSelector((state) => state.storePagination)
    const dispatch = useDispatch();

    return <>
        <button className={`flex justify-between items-center bg-secondary text-white rounded-2xl py-1 px-3 md:px-4 text-sm md:text-lg ${storePagination.currentPage < storePagination.pageCount ? "" : "pointer-events-none opacity-60"}`}
            onClick={() => {
                dispatch(nextPage())
                dispatch(startStore({ startStore: storePagination.currentPage * storePagination.storePerPage }))
                dispatch(endStore({ endStore: (storePagination.currentPage * storePagination.storePerPage) + storePagination.storePerPage }))
            }}
        >
            <span>Next</span>
            <FaChevronCircleRight className='ml-2' />
        </button>
    </>
}

const Pages = () => {
    const storePagination = useSelector((state) => state.storePagination)
    const dispatch = useDispatch();

    const pageArr = []
    for (let page = 0; page < storePagination.pageCount; page++) {
        pageArr.push("")
    }

    const handleCurrentPage = (index) => {
        dispatch(customPage({ currentPage: index }))

        storePagination.currentPage > index && dispatch(startStore({ startStore: (index * storePagination.storePerPage) - storePagination.storePerPage }))
        dispatch(endStore({ endStore: (index - 1) * storePagination.storePerPage }))

        storePagination.currentPage < index && dispatch(startStore({ startStore: (index - 1) * storePagination.storePerPage }))
        dispatch(endStore({ endStore: index * storePagination.storePerPage }))
    }

    return <>
        <div className='flex items-center'>
            {
                pageArr?.map((page, index) => (
                    storePagination.currentPage == index + 1 ? <button className='mx-1 rounded-full shadow border-2 border-primary flex justify-center items-center font-medium bg-primary text-white w-[25px] md:w-[40px] h-[25px] md:h-[40px]'>
                        <span>{index + 1}</span>
                    </button> :
                        (
                            (storePagination.currentPage == index + 2) || storePagination.currentPage == (index + 1) - 1 ? <button className='mx-1 rounded-full shadow-xl border-2 border-primary flex justify-center items-center w-[25px] md:w-[40px] h-[25px] md:h-[40px]'
                                onClick={() => handleCurrentPage(index + 1)}>
                                <span>{index + 1}</span>
                            </button> : ""
                        )
                ))
            }
            {/* {pageArr.length !== storePagination.currentPage && <button className='mx-1 rounded-full shadow-xl border-2 border-primary flex justify-center items-center w-[25px] md:w-[40px] h-[25px] md:h-[40px]'
                onClick={() => handleCurrentPage(pageArr.length)}>
                <span>{pageArr.length}</span>
            </button>} */}
        </div>
    </>
}