import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Header from '../../components/admin/Header'
import Modal from '../../components/admin/home/Modal'
import Pagination from '../../components/admin/home/Pagination'
import SearchAndResultOption from '../../components/admin/home/SearchAndResultOption'
import StoreHeaderInfo from '../../components/admin/home/StoreHeaderInfo'
import Stores from '../../components/admin/home/Stores'
import { getAllStore } from '../../redux/actions/stores'

function Home() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllStore())
    }, [dispatch])

    return (
        <>
            <Header></Header>
            <StoreHeaderInfo></StoreHeaderInfo>
            <SearchAndResultOption ></SearchAndResultOption>
            <Stores></Stores>
            <Pagination ></Pagination>
            <Modal></Modal>
        </>
    )
}

export default Home