import React from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import axios from "axios"
import { useSelector } from 'react-redux'

function MessageBox({ credit, setCredit, subscribers }) {

    const [totalCreditNeed, setTotalCreditNeed] = useState(0)
    const store = useSelector((state) => state.stores)

    const handleWordCount = (e) => {
        // const count = parseInt(e.target.value.match(/\w+/g).length)
        const count = parseInt(e.target.value.length)
        const countCeil = Math.ceil(count / 160)
        const totalCredit = countCeil * subscribers
        setTotalCreditNeed(totalCredit)
    }

    const handleSendMessage = async () => {
        if (totalCreditNeed > credit) {
            toast.error("You can't send words more than your credit")
        }
        else {

            const messageField = document.getElementById("messageField")
            const messageFieldValue = messageField.value
            const { data } = await axios.post(`https://mobile-pigeon-web-app.herokuapp.com/api/stores/sendMessage/${store?.apiKey}`, {
                fromNumber: store.textNumber,
                groupName: store.groupName,
                message: messageFieldValue
            })

            console.log(data)

            if (data) {
                setCredit(credit - totalCreditNeed)
                messageField.value = ""
                toast.success("Message has been sent to the subscribers")
            }
            else {
                toast.error("Sorry something went wrong")
            }
        }
    }

    return (
        <section className="my-6">
            <div className="container mx-auto px-4">
                <div className="wrapper max-w-md w-full mx-auto shadow rounded p-4">
                    <h2 className='text-2xl'>Send Message To Subscribers:</h2>
                    {totalCreditNeed > credit && <p className='text-error'>You can't send words in your message more than your credit</p>}
                    <p>Note: <span className='text-xs text-gray'>1 credit is charged for each 160 character segment. If you have a message that is 300 characters, and you are sending to 10 people, 20 credits will be deducted (2 credits for each person)</span></p>
                    <textarea name="" cols="30" rows="10"
                        id="messageField"
                        className={`w-full bg-slate-400 outline-none rounded my-4 pl-2 text-white ${totalCreditNeed > credit ? "border-2 border-red-400" : ""}`}
                        onChange={handleWordCount}
                    >
                    </textarea>
                    <button
                        className={`bg-primary px-6 py-1 rounded text-xl text-white ${totalCreditNeed > credit ? "pointer-events-none opacity-50" : ""}`}
                        onClick={handleSendMessage}
                    >Send Message</button>
                </div>
            </div>
        </section>
    )
}

export default MessageBox