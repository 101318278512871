/* eslint-disable import/no-anonymous-default-export */
export default (stores = [], action) => {
    switch (action.type) {
        case "GET_ALL_STORE":
            return action.payload;
        case "ADD_NEW_STORE":
            return [action.payload, ...stores]
        case "UPDATE_STORE":
            return stores.map((store) => store._id === action.payload._id ? action.payload : store)
        case "DELETE_STORE":
            return stores.filter((store) => store._id != action.payload._id)
        case "SEARCH_STORE":
            return action.payload
        case "GET_A_STORE":
            return action.payload
        default:
            return stores
    }
}