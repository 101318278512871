import React from 'react'
import { FaSpinner } from "react-icons/fa"

function Loading() {
    return (
        <section className="loading">
            <div className="contianer mx-auto px-5">
                <div className="flex justify-center items-center h-screen">
                    <span className='animate-spin'>
                        <FaSpinner className="text-5xl md:text-8xl" />
                    </span>
                </div>
            </div>
        </section>
    )
}

export default Loading