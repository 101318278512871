/* eslint-disable import/no-anonymous-default-export */
export default (modalInfo = { modalStatus: "", store: {} }, action) => {
    switch (action.type) {
        case "ADD_ADMIN_MODAL":
            return { modalStatus: "ADD_ADMIN_MODAL", store: action.payload }
        case "EDIT_ADMIN_MODAL":
            return { modalStatus: "EDIT_ADMIN_MODAL", store: action.payload }
        case "VIEW_ADMIN_MODAL":
            return { modalStatus: "VIEW_ADMIN_MODAL", store: action.payload }
        case "CLOSE_ADMIN_MODAL":
            return { modalStatus: "", store: {} }
        default:
            return modalInfo
    }
}