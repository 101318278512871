import React from 'react'

function Header() {
    return (
        <section className='my-4'>
            <div className="container mx-auto px-4">
                <div className="flex justify-center items-center">
                    <img src={require("../../images/Mobile-Pigeon-logo.png")} alt='' style={{ maxWidth: "100px", width: "100%" }} className="shadow rounded" />
                </div>
            </div>
        </section>
    )
}

export default Header