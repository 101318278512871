import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from "react-router-dom"
import Loading from '../../components/Loading'

function RequireAdmin({ children }) {

    const location = useLocation()
    const admin = useSelector((state) => state.adminData)
    const [isAdminLoading, setIsAdminLoading] = useState(true)

    console.log(admin)

    if (isAdminLoading & !admin & admin?.role != "admin") {
        return <Loading></Loading>
    }

    if (!admin || admin?.role != "admin") {
        return <Navigate to="/adminlogin" state={{ from: location }} replace></Navigate>
    }

    return children
}

export default RequireAdmin