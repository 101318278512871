import * as api from '../../api'

// Action Creators

export const getAllStore = () => async (dispatch) => {
    try {
        const { data } = await api.getAllStore()

        dispatch({ type: "GET_ALL_STORE", payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const addNewStore = (store) => async (dispatch) => {
    try {
        const { data } = await api.addNewStore(store)

        dispatch({ type: "ADD_NEW_STORE", payload: data })
    } catch (error) {
        console.log(error.message);
    }
}

export const updateStore = (id, store) => async (dispatch) => {
    try {
        const { data } = await api.updateStore(id, store)
        dispatch({ type: "UPDATE_STORE", payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const deleteStore = (id) => async (dispatch) => {
    try {
        const { data } = await api.deleteStore(id)
        dispatch({ type: "DELETE_STORE", payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const searchStore = (searchTerms) => async (dispatch) => {
    try {
        const { data } = await api.getSearchStore(searchTerms)
        dispatch({ type: "SEARCH_STORE", payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const getAStore = (storeId) => async (dispatch) => {
    try {
        const { data } = await api.getAStore(storeId)
        dispatch({ type: "GET_A_STORE", payload: data })
    } catch (error) {
        console.log(error.message)
    }
}