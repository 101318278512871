import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { closeStoreModal } from '../../../redux/actions/adminModals'
import { FaPlusCircle, FaTimesCircle, FaEdit, FaTrashAlt } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useEffect } from 'react'
import { addNewStore, deleteStore, updateStore } from '../../../redux/actions/stores'

function Modal() {
    const dispatch = useDispatch()

    const modalInfo = useSelector((state) => state.adminModals)
    const modalStatus = modalInfo.modalStatus
    const store = modalInfo.store

    const [storeData, setStoreData] = useState({ _id: "", storeName: "", textNumber: "", apiKey: "", groupName: "", username: "", password: "", storeImageUrl: "", storeEmail: "" })

    useEffect(() => {
        setStoreData(store)
    }, [store])

    const handleCloseModal = () => {
        dispatch(closeStoreModal())
    }

    const handleAddStore = (e) => {
        e.preventDefault()
        const storeImageUrl = e.target.storeImageUrl.value
        const storeName = e.target.storeName.value
        const textNumber = e.target.textNumber.value
        const apiKey = e.target.apiKey.value
        const groupName = e.target.groupName.value
        const username = e.target.username.value
        const password = e.target.password.value
        const email = e.target.storeEmail.value

        if (!storeName || !textNumber || !apiKey || !groupName || !username || !password || !storeImageUrl || !email) {
            return toast.warn("All Input Field Are Required")
        }

        const store = { storeName, textNumber, apiKey, groupName, username, password, storeImageUrl, email }

        dispatch(addNewStore(store))
        toast.success("Store Added Successfully!")
        e.target.reset()
    }

    const handleEditStore = () => {
        dispatch(updateStore(storeData._id, storeData))
        toast.success(`${storeData.storeName} is updated successfully`)
    }

    const handleDeleteStore = () => {
        // eslint-disable-next-line no-restricted-globals
        const confirmDelete = confirm(`Are you sure you want to delete ${storeData.storeName}`)
        if (confirmDelete) {
            dispatch(deleteStore(storeData._id), closeStoreModal())
            toast.success(`${storeData.storeName} is deleted successfully ${storeData._id}`)
        }
        else {
            toast.error(`${storeData.storeName} Store can't delete, because you cancel confirmation`)
        }
    }

    return (
        <>
            <input type="checkbox" id="my-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-2xl">
                    {
                        modalStatus === "ADD_ADMIN_MODAL" && (
                            <div>
                                <h2 className='text-center text-3xl font-semibold'>Add New Store</h2>
                                <form onSubmit={handleAddStore}>
                                    <div className="wrapper grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-7 my-5">
                                        <div>
                                            <label htmlFor="" className='text-sm'>Store Name:</label>
                                            <input type="text" name='storeName' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='text-sm'>Text Number:</label>
                                            <input type="text" name='textNumber' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='text-sm'>API Key:</label>
                                            <input type="text" name='apiKey' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='text-sm'>Group Name:</label>
                                            <input type="text" name='groupName' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='text-sm'>Username:</label>
                                            <input type="text" name='username' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='text-sm'>Password:</label>
                                            <input type="text" name='password' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='text-sm'>Store Image Url:</label>
                                            <input type="text" name='storeImageUrl' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' />
                                        </div>
                                        <div>
                                            <label htmlFor="" className='text-sm'>Email for send info:</label>
                                            <input type="text" name='storeEmail' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' />
                                        </div>
                                        <div>
                                            <button className='bg-primary text-white py-2 px-14 font-medium flex items-center w-full justify-center rounded'>
                                                <span>ADD STORE</span>
                                                <FaPlusCircle className='ml-3' />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }
                    {
                        modalStatus === "EDIT_ADMIN_MODAL" && (
                            <div>
                                <h2 className='text-center text-3xl font-semibold'>Edit Store ({storeData.storeName})</h2>
                                <div className="wrapper grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-7 my-5">
                                    <div>
                                        <label htmlFor="" className='text-sm'>Store Name:</label>
                                        <input type="text" name='storeName' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md text-slate-400' value={storeData.storeName} onChange={(e) => setStoreData({ ...storeData, storeName: e.target.value })} />
                                    </div>
                                    <div>
                                        <label htmlFor="" className='text-sm'>Text Number:</label>
                                        <input type="text" name='textNumber' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' value={storeData.textNumber} onChange={(e) => setStoreData({ ...storeData, textNumber: e.target.value })} />
                                    </div>
                                    <div>
                                        <label htmlFor="" className='text-sm'>API Key:</label>
                                        <input type="text" name='apiKey' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' value={storeData.apiKey} onChange={(e) => setStoreData({ ...storeData, apiKey: e.target.value })} />
                                    </div>
                                    <div>
                                        <label htmlFor="" className='text-sm'>Group Name:</label>
                                        <input type="text" name='groupName' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' value={storeData.groupName} onChange={(e) => setStoreData({ ...storeData, groupName: e.target.value })} />
                                    </div>
                                    <div>
                                        <label htmlFor="" className='text-sm'>Username:</label>
                                        <input type="text" name='username' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md disabled' value={storeData.username} onChange={(e) => setStoreData({ ...storeData, username: e.target.value })} readOnly />
                                    </div>
                                    <div>
                                        <label htmlFor="" className='text-sm'>Password:</label>
                                        <input type="text" name='password' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md disabled' value={storeData.password} onChange={(e) => setStoreData({ ...storeData, password: e.target.value })} readOnly />
                                    </div>
                                    <div>
                                        <label htmlFor="" className='text-sm'>Store Image Url:</label>
                                        <input type="text" name='storeImageUrl' className='w-full border-2 shadow-primary rounded-lg outline-none py-1 pl-2 text-md' value={storeData.storeImageUrl} onChange={(e) => setStoreData({ ...storeData, storeImageUrl: e.target.value })} />
                                    </div>
                                    <div className='flex items-end'>
                                        <button className='bg-primary text-white py-2 px-14 font-medium flex items-center w-full justify-center rounded'
                                            onClick={handleEditStore}>
                                            <span>EDIT STORE</span>
                                            <FaEdit className='ml-3' />
                                        </button>
                                    </div>
                                    <div className='flex items-end modal-action'>
                                        <label htmlFor="my-modal" className="bg-[#EF1A1A] text-white py-2 px-14 font-medium flex items-center w-full justify-center rounded cursor-pointer"
                                            onClick={handleDeleteStore}>
                                            <span>DELETE STORE</span>
                                            <FaTrashAlt className='ml-3' />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        modalStatus === "VIEW_ADMIN_MODAL" && (
                            <div>
                                <h2 className='text-center text-3xl font-medium'>{store?.storeName} Store Info</h2>
                                <div className="my-8 flex justify-center">
                                    <ul>
                                        <li>Store Name </li>
                                        <li>Text Number </li>
                                        <li>API Key </li>
                                        <li>Group Name </li>
                                        <li>Username </li>
                                        <li>Password </li>
                                        <li>Store Image Url </li>
                                    </ul>
                                    <ul>
                                        <li>&nbsp;&nbsp;:&nbsp;&nbsp;</li>
                                        <li>&nbsp;&nbsp;:&nbsp;&nbsp;</li>
                                        <li>&nbsp;&nbsp;:&nbsp;&nbsp;</li>
                                        <li>&nbsp;&nbsp;:&nbsp;&nbsp;</li>
                                        <li>&nbsp;&nbsp;:&nbsp;&nbsp;</li>
                                        <li>&nbsp;&nbsp;:&nbsp;&nbsp;</li>
                                        <li>&nbsp;&nbsp;:&nbsp;&nbsp;</li>
                                    </ul>
                                    <ul>
                                        <li>{store?.storeName}</li>
                                        <li>{store?.textNumber}</li>
                                        <li>{store?.apiKey}</li>
                                        <li>{store?.groupName}</li>
                                        <li>{store?.username}</li>
                                        <li>{store?.password}</li>
                                        <li >
                                            <img src={store?.storeImageUrl} alt="" style={{ maxWidth: "60px", width: "100%" }} />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                    <div className="modal-action flex justify-center">
                        <label htmlFor="my-modal" className="py-2 px-14 rounded cursor-pointer flex items-center text-white font-medium bg-[#EF1A1A] uppercase"
                            onClick={() => handleCloseModal()}
                        >
                            <span>Close</span>
                            <FaTimesCircle className='ml-3' />
                        </label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal