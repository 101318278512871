
export const previousPage = () => async (dispatch) => {
    try {
        dispatch({ type: "PREVIOUS_PAGE" })
    } catch (error) {
        console.log(error)
    }
}

export const nextPage = () => async (dispatch) => {
    try {
        dispatch({ type: "NEXT_PAGE" })
    } catch (error) {
        console.log(error)
    }
}

export const customPage = (payload) => async (dispatch) => {
    try {
        dispatch({ type: "CUSTOM_PAGE", payload: payload })
    } catch (error) {
        console.log(error)
    }
}

export const pageCount = (payload) => async (dispatch) => {
    try {
        dispatch({ type: "PAGE_COUNT", payload: payload })
    } catch (error) {
        console.log(error)
    }
}

export const storePerPage = (payload) => async (dispatch) => {
    try {
        dispatch({ type: "STORE_PER_PAGE", payload: payload })
    } catch (error) {
        console.log(error)
    }
}

export const startStore = (payload) => async (dispatch) => {
    try {
        dispatch({ type: "START_STORE", payload: payload })
    } catch (error) {
        console.log(error)
    }
}

export const endStore = (payload) => async (dispatch) => {
    try {
        dispatch({ type: "END_STORE", payload: payload })
    } catch (error) {
        console.log(error)
    }
}