import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import Loading from '../../components/Loading'
import Header from '../../components/users/Header'
import Info from '../../components/users/Info'
import MessageBox from '../../components/users/MessageBox'
import { getAStore } from '../../redux/actions/stores'

function UserHome() {

    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const dispatch = useDispatch()
    const store = useSelector((state) => state.stores)
    const [credit, setCredit] = useState(10)
    const [subscribers, setSubscribers] = useState(100)
    const [loading, setLoading] = useState(true)

    const queryUsername = searchParams.get("username")
    const queryPassword = searchParams.get("password")
    const queryApiKey = searchParams.get("apiKey")
    const queryStoreId = searchParams.get("storeId")

    useEffect(() => {
        dispatch(getAStore(queryStoreId))
    }, [dispatch, queryStoreId])

    useEffect(() => {
        const baseUrl = "https://mobile-pigeon-web-app.herokuapp.com/api/stores"
        const getCredit = async () => {
            const { data } = await axios.get(`${baseUrl}/credit/${store?.apiKey}`)
            console.log(data);
            setCredit(data?.credit)
        }
        getCredit()
        const getSubscribers = async () => {
            const { data } = await axios.get(`${baseUrl}/subscribers/${store?.apiKey}`)
            console.log(data);
            setSubscribers(data.subscribers == "-7" ? 0 : data.subscribers)
        }
        getSubscribers()
        setLoading(false)
    }, [store])


    if (loading) {
        return <Loading></Loading>
    }

    if (!queryUsername || !queryPassword || !queryApiKey || !queryStoreId) {
        return <>
            <div className='h-screen bg-primary flex justify-center items-center'>
                <h2 className='text-4xl text-slate-100'>You are in wrong url. Go to your email and check the url that admin Sent you.</h2>
            </div>
        </>
    }

    if (store?._id != queryStoreId || store?.username != queryUsername || store?.password != queryPassword || store?.apiKey != queryApiKey) {

        return <>
            <div className='h-screen bg-primary flex justify-center items-center'>
                <h2 className='text-4xl text-slate-100'>You are in wrong url. Go to your email and check the url that admin Sent you</h2>
            </div>
        </>
    }


    return (
        <>
            <Header></Header>
            <Info credit={credit} subscribers={subscribers}></Info>
            <MessageBox credit={credit} setCredit={setCredit} subscribers={subscribers}></MessageBox>
        </>
    )
}

export default UserHome