/* eslint-disable import/no-anonymous-default-export */
export default (paginationInfo = { pageCount: 1, currentPage: 1, storePerPage: 2, startStore: 0, endStore: 2 }, action) => {
    switch (action.type) {
        case "PREVIOUS_PAGE":
            return { ...paginationInfo, currentPage: paginationInfo.currentPage - 1 }
        case "NEXT_PAGE":
            return { ...paginationInfo, currentPage: paginationInfo.currentPage + 1 }
        case "CUSTOM_PAGE":
            return { ...paginationInfo, currentPage: action.payload.currentPage }
        case "PAGE_COUNT":
            return { ...paginationInfo, pageCount: action.payload.pageCount }
        case "STORE_PER_PAGE":
            return { ...paginationInfo, storePerPage: action.payload.storePerPage }
        case "START_STORE":
            return { ...paginationInfo, startStore: action.payload.startStore }
        case "END_STORE":
            return { ...paginationInfo, endStore: action.payload.endStore }
        default:
            return { ...paginationInfo }
    }
}