import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <header className='container mx-auto bg-[#bff9de] p-3'>
            <div className="flex justify-between items-center">
                <h2 className='text-lg md:text-3xl font-medium'>Mobile Pigeon</h2>
                <Link to="/admin" className='rounded overflow-hidden' style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.4)" }}>
                    <img src={require("../../images/Mobile-Pigeon-logo.png")} style={{ maxWidth: "100px", width: "100%" }} alt="" />
                </Link>
                <h2 className='text-lg md:text-3xl text-[#AAAAAA]'>Admin Area</h2>
            </div>
        </header>
    )
}

export default Header