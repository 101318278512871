import React from 'react'
import { FaSearch } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux'
import { endStore, pageCount, startStore, storePerPage } from '../../../redux/actions/storePagination'
import { getAllStore, searchStore } from '../../../redux/actions/stores'


function SearchAndResultOption() {
    const dispatch = useDispatch()

    const handleSearchSubmit = (e) => {
        e.preventDefault()
        const searchValue = e.target.search.value
        const searchBy = e.target.searchBy.value
        if (searchValue.length > 1) {
            dispatch(searchStore({ searchValue, searchBy }))
        }
        else {
            dispatch(getAllStore())
        }
        // alert(`Search Value is: ${searchValue} AND Search By: ${searchBy}`)
    }

    return (
        <section className="search-and-result-option my-7">
            <div className="container mx-auto px-3 md:px-0">
                <div className="wrapper flex justify-between items-center">
                    <SearchBox handleSearchSubmit={handleSearchSubmit} />
                    <ResultOption />
                </div>
            </div>
        </section>
    )
}

export default SearchAndResultOption


const SearchBox = ({ handleSearchSubmit }) => (
    <form onSubmit={handleSearchSubmit}>
        <div className='bg-slate-100 p-2 md:p-1 rounded md:rounded-full shadow-md flex items-center flex-col md:flex-row'>
            <div>
                <input type="text" name='search' placeholder='Search...' className='md:rounded-l-3xl pl-2 py-2 outline-none' />
            </div>
            <div className='bg-[#5E5E5E] md:mx-2 my-2 md:my-0'>
                <select name="searchBy" id="" className='bg-[#5E5E5E] text-white h-fit py-2 text-center'>
                    <option value="storeName" style={{ fontSize: "12px" }}>Search By Name</option>
                    <option value="apiKey" style={{ fontSize: "12px" }}>Search By API Key</option>
                    <option value="textNumber" style={{ fontSize: "12px" }}>Search By Text Number</option>
                </select>
            </div>
            <div className='w-full'>
                <button className='flex items-center text-white font-medium bg-primary px-7 py-2 md:rounded-r-3xl w-full'>
                    <span>Search</span>
                    <FaSearch style={{ marginLeft: "10px" }} />
                </button>
            </div>
        </div>
    </form>
)

const ResultOption = () => {
    const stores = useSelector((state) => state.stores)
    const storePagination = useSelector((state) => state.storePagination)
    const dispatch = useDispatch()

    const handleStorePerPage = (e) => {
        const perPageValue = parseInt(e.target.value)
        dispatch(storePerPage({ storePerPage: perPageValue }))
        dispatch(pageCount({ pageCount: Math.ceil(stores.length / perPageValue) }))
        dispatch(startStore({ startStore: (storePagination.currentPage - 1) * perPageValue }))
        dispatch(endStore({ endStore: storePagination.currentPage * perPageValue }))
    }

    return <div>
        <select name="result-per-page" id="result-per-page" className='bg-[#F2F0F0] border-2 rounded py-1 px-3' onChange={handleStorePerPage}>
            <option value="2">Result per page 10 </option>
            <option value="4">Result per page 20 </option>
            <option value="6">Result per page 50 </option>
            <option value="8">Result per page 100 </option>
        </select>
    </div>
}