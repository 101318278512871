import * as api from '../../api'

// Action Creators

export const adminLogin = (username, password) => async (dispatch) => {
    try {
        const { data } = await api.adminLogin(username, password)
        dispatch({ type: "ADMIN_LOGIN", payload: data })
    }
    catch (error) {
        console.log(error.message)
    }
}