import React from 'react'
import { FaPlusCircle } from "react-icons/fa"
import { useDispatch } from 'react-redux'
import { openAddStoreModal } from "../../../redux/actions/adminModals.js"

function StoreHeaderInfo() {
    const dispatch = useDispatch()

    const handleModal = () => {
        dispatch(openAddStoreModal())
    }

    return (
        <section className='store-header-info my-4'>
            <div className="container mx-auto px-3 md:px-0">
                <div className="wrapper flex justify-between items-center">
                    <div className="left-info">
                        <h2 className='text-lg md:text-3xl font-medium'>Stores</h2>
                        <p className='text-[#5E5E5E]'>Below are all store that you added</p>
                    </div>
                    <div className="right-info">
                        <label htmlFor="my-modal" className="modal-button bg-primary hover:bg-secondary text-white py-2 px-10 rounded font-medium flex items-center cursor-pointer"
                            onClick={() => handleModal()}
                        >
                            <span>ADD STORE</span>
                            <FaPlusCircle style={{ marginLeft: "10px" }} />
                        </label>
                    </div>
                </div>
                <hr style={{ borderBottom: "2px solid #D9D9D9" }} />
            </div>
        </section >
    )
}

export default StoreHeaderInfo