import { Routes, Route, Link } from 'react-router-dom'
import Home from './pages/admin/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserHome from './pages/users/UserHome';
import NotFound from './pages/shared/NotFound';
import RequireAdmin from './pages/admin/RequireAdmin';
import AdminLogin from './pages/admin/AdminLogin';


function App() {
  return (
    <>
      {/* <Link to="/admin">Admin</Link>
      <Link to="/user/finaltest/123456/INS1059443581/63220fe0999ab9232d37cae5">User</Link> */}
      {/* <Link to="/user?finaltest=majed&password=123456&apiKey=INS1059443581&storeId=63220fe0999ab9232d37cae5">User</Link> */}
      <Routes>
        <Route path="/admin" element={
          <RequireAdmin>
            <Home></Home>
          </RequireAdmin>
        }></Route>
        <Route path="adminlogin" element={<AdminLogin></AdminLogin>}></Route>
        {/* <Route path="/user/:username/:password/:api/:storeId" element={<UserHome></UserHome>}></Route> */}
        <Route path="/user" element={<UserHome></UserHome>}></Route>
        <Route path="*" element={<NotFound></NotFound>}></Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
